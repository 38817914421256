.mt-300 {
  padding-top: 300px;
}

.bg-nude {
  background-color: #d4c4b0;
  color: #0a0c0d;
}

.navbar-brand {
  color: #0a0c0d;
}

.header-text {
  font-size: .8rem;
}

.bg-text {
  backdrop-filter: blur(1px) saturate(100%) opacity(.8);
  color: #0a0c0d;
}

.bg-header {
  background-color: #0a0c0d !important;
}

.pt-50 {
  padding-top: 50px;
}


.badge-nude {
  color: #fff;
  background-color: #d4c4b0;
}

.btn-nude {
  color: #fff;
  background-color: #d4c4b0;
  border-color: #d4c4b0;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.btn-nude:hover {
  color: #fff;
  background-color: #C6B095;
  border-color: #d4c4b0;
}
.btn-nude:focus, .btn-nude.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(118, 135, 232, 0.5);
}
.btn-nude.disabled, .btn-nude:disabled {
  color: #fff;
  background-color: #E2D7CA;
  border-color: #E2D7CA;
}
.btn-nude:not(:disabled):not(.disabled):active, .btn-nude:not(:disabled):not(.disabled).active, .show > .btn-nude.dropdown-toggle {
  color: #fff;
  background-color: #C6B095;
  border-color: #C6B095;
}
.btn-nude:not(:disabled):not(.disabled):active:focus, .btn-nude:not(:disabled):not(.disabled).active:focus, .show > .btn-nude.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(118, 135, 232, 0.5);
}

.icon-shape-nude {
  color: #d4c4b0;
  background-color: rgba(212, 196, 176, 0.5);
}

.text-nude {
  color: #d4c4b0 !important;
}

.page-header {
  max-height: 3000px;
}

.copyright {
  a {
    color: #d4c4b0;
  }
}

.footer {
    background-color: #f8f5f2;
}

.features-1 {
  .info {
    max-width: 450px;
    text-align: center;
  }
}

.card-profile {
  max-width: 350px;
  min-width: 275px;
}
